import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftRightIcon,
  InboxIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useRef, useEffect, useState } from "react";
import SvvsdLogo from "../images/svvsd-logo.png";

import {
  ChartBarIcon,
  CheckCircleIcon,
  CursorArrowRaysIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

// const callsToAction = [
//   {
//     name: "Career Elevation and Technology Center",
//     href: "/schools/cetc",
//     icon: "https://cdn.sanity.io/images/mx4mn3pt/production/f5a3728dcb2fc44b2e3564d965a454fe333e1909-500x462.png?w=64&h=64&&auto=format",
//   },
//   {
//     name: "Innovation Center",
//     href: "/schools/innovation",
//     icon: "https://cdn.sanity.io/images/mx4mn3pt/production/5e3890409180b236f7494eb1cde1ae1ba5450cf4-1200x1333.png?w=64&h=64&&auto=format",
//   },
// ];

const schools = [
  {
    name: "Altona Middle School",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/ams",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/40315c3d15ad2cd5a38e95e362f9b39ec017fd15-306x273.svg?w=64&h=64&&auto=format",
  },
  {
    name: "Coal Ridge Middle School",
    description:
      "Building on a foundation of trust, honor, and compassion, we learn, lead, and succeed.",
    href: "/schools/crms",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/0207af804622fd4fdbb4d82e7cc84d4e110923e1-392x355.svg?w=64&h=64&&auto=format",
  },
  {
    name: "Erie Middle School",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/ems",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/67ee78e6ee547b8db977b49d1d9f47e1364e1158-228x154.svg?w=64&h=64&&auto=format",
  },

  {
    name: "LaunchED Virtual Academy",
    description:
      "The Trojan Way - Tradition, Respect, Ownership, Journey, Achieve, Never Quit.",
    href: "/schools/launched",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/85eeb27a56fb067a160e89c60c8462a515855f76-482x326.svg?w=64&h=64&&auto=format",
  },

  {
    name: "Longs Peak Middle School",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/lpms",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/0190efcf154b8245b3dbd5fcd76bad52f5f43d3f-1536x1169.png?w=64&h=64&&auto=format",
  },
  {
    name: "Lyons Middle Senior High School",
    description: "We prepare students for the ever-changing world.",
    href: "/schools/lmshs",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/d7d1c1a68604207b1420756d848d5ce7d802e283-218x232.svg?w=64&h=64&&auto=format",
  },
  {
    name: "Mead Middle School",
    description:
      "Sunset spartans are empowered through an engaged and empathetic learning community.",
    href: "/schools/mms",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/2594b16869b77cc0c182a924a0123416df8ab499-247x279.svg?w=150&h=150&fit=crop&auto=format&q=80",
  },
  {
    name: "Soaring Heights PK-8",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/shpk8",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/06d1904cfd0d5f249b36ebe4dc39e6dc4463366b-140x104.svg?w=64&h=64&&auto=format",
  },

  {
    name: "Sunset Middle School",
    description:
      "Sunset spartans are empowered through an engaged and empathetic learning community.",
    href: "/schools/sms",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/9d1c4229be9330379e63e3aeb79e277abae38684-414x328.svg?w=150&h=150&fit=crop&auto=format&q=80",
  },
  {
    name: "Thunder Valley K-8",
    description:
      "Our mission is to challenge students to achieve the highest levels of academic success so they may thrive in an increasingly diverse and complex world.",
    href: "/schools/tvk8",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/83e917c936b1b82153ff929508a2fec54afbae18-245x188.svg?w=64&h=64&&auto=format",
  },
  {
    name: "Timberline PK-8",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/tpk8",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/66ec65c77610ec2f6b913ce3e90d0c62265e2b50-265x183.svg?w=64&h=64&&auto=format",
  },
  {
    name: "Trail Ridge Middle School",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/trms",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/1b7fdffb3b39a4be09aa1c9b363e4106ac1415fe-250x255.svg?w=64&h=64&&auto=format",
  },
  {
    name: "Westview Middle School",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/wms",
    icon: "https://cdn.sanity.io/images/md6vyvkt/production/8344580c09288d516a393e04e165d94d6c1df4bd-259x283.svg?w=64&h=64&&auto=format",
  },
];
const navigation = [
  { name: "The St. Vrain Advantage", href: "/#advantage" },
  { name: "Rigorous Academics", href: "/#rigorous-academics" },
  { name: "High School Options", href: "https://hsoptions.svvsd.org" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HomeHero() {
  return (
    <div className="bg-white">
      <header>
        <Popover className="relative bg-white">
          <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto sm:h-10 flex-shrink-0 object-contain"
                  src={SvvsdLogo}
                  alt=""
                />
              </a>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#862633]">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden space-x-10 md:flex">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target={item.href.includes("http") ? "_blank" : "_self"}
                  rel="noreferrer noopener"
                  className="text-base font-medium text-center text-gray-500 hover:text-gray-900"
                >
                  {item.name}
                </a>
              ))}
              <Popover className="">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#862633] focus:ring-offset-2"
                      )}
                    >
                      <span>Middle School Options</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="absolute inset-x-0 z-50 transform shadow-lg mt-2">
                        <div className="bg-white">
                          <div className="mx-auto max-w-7xl grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                            {schools.map((item) => (
                              <Popover.Button
                                as={Link}
                                key={item.name}
                                to={item.href}
                                className="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                              >
                                <div className="flex md:h-full lg:flex-row items-center gap-2 justify-center">
                                  <div className="flex-shrink-0">
                                    <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-white text-white sm:h-12 sm:w-12 overflow-hidden shadow p-2">
                                      {item.icon && (
                                        <img
                                          src={item.icon}
                                          className="w-full h-full object-contain"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 ">
                                    <div>
                                      <p className="text-base font-medium text-gray-900">
                                        {item.name}
                                      </p>
                                      {/* <p className="mt-1 text-sm text-gray-500">
                                        {item.description}
                                      </p> */}
                                    </div>
                                  </div>
                                </div>
                              </Popover.Button>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="fixed h-screen overflow-hidden inset-x-0 top-0 z-[999] origin-top-right transform transition md:hidden"
            >
              <div className="divide-y-2 divide-gray-50  bg-white shadow-lg ring-1 ring-black ring-opacity-5 pb-8">
                <div className="px-5 pt-5 pb-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={SvvsdLogo} />
                    </div>
                    <div className="-mr-2 sticky top-0">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#862633]">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid grid-cols-1 relative gap-7 max-h-[calc(100vh-240px)] overflow-y-auto overflow-x-hidden pb-12">
                      <span className="text-gray-600 text-xs uppercase mt-2">
                        Middle School Options
                      </span>
                      {schools.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                        >
                          <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-white text-white sm:h-12 sm:w-12 overflow-hidden shadow p-2">
                            {item.icon && (
                              <img
                                src={item.icon}
                                className="w-full h-full object-contain"
                              />
                            )}
                          </div>

                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </a>
                      ))}
                    
                
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </header>
    </div>
  );
}
