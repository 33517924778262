import { NavBar } from "../components/NavBar";
import { useEffect, useState } from "react";
import { SectionHeading } from "../components/SectionHeading";
import { Container } from "../components/Container";
import FocusPrograms from "./focusPrograms";
import { VideoTestimonial } from "../components/VideoTestimonial";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { Accordion } from "../components/Accordion";
import BlueGlobe from "../images/BlueGlobe.jpg";
import Grad from "../images/grad.jpg";
import { Hero } from "../components/Hero";
import SvvsdLogo from "../images/svvsd-logo.png";
import msoptionsCover from "../images/msoptions-cover.jpg";
import Medical from "../images/medical.jpg";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import SiteNavigation from "../components/SiteNavigation";

import { Link } from "react-router-dom";
import { Highlights } from "../components/Highlights";
import { urlFor } from "../utils/imageBuilder";
import { Footer } from "../components/Footer";
import HomeHero from "../components/HomeHero";

const builder = imageUrlBuilder(sanityClient);

export default function Home() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "school"]{
          title,
          slug,
          logo, 
          color,
          mainImage,
          seoDescription,
        }
      `
      )
      .then((data) => {
        setAllPosts(data);
        console.log(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--bg-color", "#862633");
    document.documentElement.style.setProperty("--bg-color-light", "#86263311");
  }, []);


  const [hashScrolled, setHashScrolled] = useState(false);

  useEffect(() => {
    if (allPostsData && !hashScrolled) {
      setHashScrolled(true);
      if (window.location.hash) {
        const hash = window.location.hash;
        const element = document.querySelector(hash);

        if (element) {
          const elementPosition = element.getBoundingClientRect().top;

          const offsetPosition = elementPosition;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
            duration: 1000,
          });
        }
      }
    }
  }, [allPostsData]);

  return (
    <>
    <SiteNavigation />
    <HomeHero />
    {/* <SiteNavigation /> */}

      {/* <Hero
        title="Guide to Middle School Options"
        staticImage={msoptionsCover}
        staticLogo={SvvsdLogo}
        subtitle="Your next journey begins here."
      /> */}
      <NavBar
        links={[
          { id: "advantage", title: "The St. Vrain Advantage" },
          { id: "rigorous-academics", title: "Academic Preperation" },
          { id: "schools", title: "Middle School Options" },
        ]}
      />
      <section id="advantage" className="pt-36 relative">
        <Container size="lg">
          <div className="relative lg:grid grid-cols-2 bg-white pb-24 gap-16">
            <div className="">
              <div className="">
                <div>
                  <div className="flex h-12 w-12 items-center justify-center rounded-md bg-[color:var(--bg-color)] text-white">
                  <i class="fa-duotone fa-earth-europe h-5 w-5"></i>
                  </div>
                </div>
                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  The St. Vrain Advantage
                </h2>
                <p className="mt-6 text-lg text-gray-500">
                This is a very exciting time in our schools as we continue to ensure student success and a strong competitive advantage for a globalized 21st-century economy. St. Vrain Valley Schools’ college preparatory and high-quality instructional focus programs – delivered through rigorous courses and engaging activities – ensure that your student will receive a world-class middle school education. We are providing current and future generations a strong competitive advantage so that all students can achieve success in a globalized world.
                </p>
                <div className="mt-8 overflow-hidden">
                  <dl className="-mt-8 grid grid-cols-2 gap-4">
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        high-quality high school focus programs
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        12
                      </dd>
                    </div>
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        credits to graduate, among the most rigorous in Colorado
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        24.5
                      </dd>
                    </div>
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        career and technical education programs at the high
                        school level
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        40+
                      </dd>
                    </div>
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        of St. Vrain secondary students are engaged in
                        co-curricular activities
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        78%
                      </dd>
                    </div>
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        graduation rate at our traditional high schools
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        93.2%
                      </dd>
                    </div>
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        business and industry partners who support student
                        advancement
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        90+
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div className="w-full h-full mt-24">
              <Accordion title="Rigrous Academic Preparation">
                <p>
                St. Vrain Valley Schools offer one of the most comprehensive middle school programs in Colorado, preparing students to excel in high school and beyond. Students engage in rigorous academic programs, including access to honors and advanced coursework, complemented by after-school tutoring and academic support.
                </p>
              </Accordion>
              <Accordion title="Advancing Excellence for All Students">
                <p>
                  St. Vrain provides a rigorous academic foundation designed to
                  prepare students for career and postsecondary readiness.
                  Additionally, we offer a robust portfolio of focus schools,
                  giving families choice in selecting a program that best fits
                  the needs and interests of their student. With over 70
                  programs to choose from, ranging from aerospace to visual and
                  performing arts, students graduate prepared for success in the
                  complex, globalized world. St. Vrain Valley Schools is moving
                  the needle on academic growth and student success to deliver a
                  strong competitive advantage for all students.{" "}
                </p>
                <a href="https://www.svvsd.org/about/district-overview/by-the-numbers-a-strong-competitive-advantage/">
                  By the Numbers: Student Achievement and Success
                </a>
              </Accordion>
              <Accordion title="Equity in St. Vrain">
                <p>
                  St. Vrain Valley Schools recognizes that well-being and
                  excellence requires a commitment to equity. The district
                  continues to work endlessly to acknowledge and address
                  structures and practices that impact student safety,
                  well-being, learning, and achievement.{" "}
                </p>
                <a href="https://www.svvsd.org/about/equity-in-st-vrain/">
                  Learn more about Equity initiatives and successes in St. Vrain
                </a>
              </Accordion>
              <Accordion title="Open Enrollment Information">
                <p>
                  Choosing a school for your student is an important personal
                  step in charting their future path. Toward this end, St. Vrain
                  offers a diverse and extensive selection of rigorous academic
                  and co-curricular programs that provide you and your student
                  choice in selecting a school that best aligns with their
                  interests and educational goals. Among our many high-quality
                  programs, we are especially proud of the outstanding portfolio
                  of options available across our schools.
                </p>
                <a href="https://www.svvsd.org/schools/enrollment/open-enrollment/">
                  Open Enrollment Information
                </a>{" "}
                |{" "}
                <a href="https://www.svvsd.org/schools/maps-boundaries/">
                  District Maps and Boundaries
                </a>
              </Accordion>
              {/* <Accordion title="Industry-Leading Focus Programs">
                <p>
                  St. Vrain Valley Schools is giving students a strong
                  competitive advantage for success in postsecondary studies and
                  careers through many outstanding focus programs, industry
                  credentials, and real-world learning experiences.
                </p>
              </Accordion> */}
              <Accordion title="District Awards and Recognitions">
                <p>
                  St. Vrain Valley Schools is a nationally recognized center of
                  excellence for advancing student academic growth and
                  opportunity through innovation, technology, and robust
                  community partnerships.
                </p>
                <a href="https://www.svvsd.org/about/district-overview/awards-recognitions/">
                  Recent Awards and Recognitions
                </a>
              </Accordion>
              <Accordion title="Safety and Well-Being">
                <p>
                  Students safety and well-being is paramount in St. Vrain, with
                  over $45 million invested in safety and security upgrades over
                  the past ten years. Additionally, St. Vrain prioritizes
                  student well-being and mental health support, with a robust
                  social emotional (SEL) curriculum at all schools, counseling
                  staff at the elementary schools, investments in additional
                  counseling support at the middle and high school levels, and
                  strong partnerships with local law enforcement, including
                  School Resource Officers stationed at all secondary schools.
                </p>
              </Accordion>
            </div>
          </div>
        </Container>
        <VideoTestimonial
          vimeoId={766967883}
          staticImage={BlueGlobe}
        >
          St. Vrain’s high-quality, comprehensive middle school options prepare students to successfully pursue many pathway options in high school and beyond.
        </VideoTestimonial>
 
       
      </section>
      <section id="rigorous-academics" className="pt-24">
        <Container size="lg" className="my-16">
          <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base mt-8 max-w-3xl">
            <div className="mb-16">
            <div className="flex h-12 w-12 items-center justify-center rounded-md bg-[color:var(--bg-color)] text-white">
                  <i class="fa-duotone fa-university h-5 w-5"></i>
                  </div>
              <h2 className="mt-6">Rigorous Academic Preparation</h2>

             
            </div>
          </div>
        
        <Highlights
          highlights={[
            {
              title: "Honors and Advanced Coursework",
              simpleDescription:
                "Our middle school students are presented with a robust suite of rigorous honors and advanced coursework options tailored to challenge and inspire.",
              imageUrl:
                "https://cdn.sanity.io/images/md6vyvkt/production/790bd1100f5ca04dbd3b6db0c7aebb6ff45dad2f-800x690.jpg?w=640",
            },
            {
              title: "Focus Program Pathways",
              simpleDescription:
                "From STEM and International Baccalaureate to pre-Advanced Placement, we cater to varied academic interests and aspirations.",
              imageUrl:
                "https://cdn.sanity.io/images/md6vyvkt/production/83dcb72fccde6810df4b68c7a0e9f71dc9aa444d-800x600.jpg?w=640",
            },
            {
              title: "Individualized Career & Academic Plans",
              simpleDescription:
                "Starting in middle school, the ICAP delves into students’ career and college interests, setting early postsecondary goals and tracking academic progress.",
              imageUrl:
                "https://cdn.sanity.io/images/md6vyvkt/production/400adb4d2af26aedbe121f39154160dacf0e3d46-640x427.png?w=640",
            },
          ]}
        />
          </Container>
          </section>
      <section id="schools" className="pt-24">
        <Container size="lg" className="my-16">
          <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base mt-8 max-w-3xl">
            <div className="mb-16">
            <div className="flex h-12 w-12 items-center justify-center rounded-md bg-[color:var(--bg-color)] text-white">
                  <i class="fa-duotone fa-graduation-cap h-5 w-5"></i>
                  </div>
              <h2 className="mt-6">Your School. Your Choice.</h2>

              <p className="text-xl text-gray-500">
                St. Vrain offers a diverse and extensive selection of rigorous
                academic and co-curricular programs that provide you and your
                student choice in selecting a school that best aligns with their
                interests and educational goals.
              </p>
              <p className="text-gray-500">
                <a href="https://svvsd.org/schools" target="_blank">Contact any school</a> in the district to schedule a tour or learn
                more about program offerings and opportunities for students.
              </p>
            </div>
          </div>
          <ol
            role="list"
            className="-mx-3 grid grid-cols-1 gap-y-10 lg:grid-cols-3 lg:text-center xl:-mx-12"
          >
            {allPostsData
              ?.sort((a, b) => a.title.localeCompare(b.title))
              .map((school) => (
                <>
                  {!school.title.includes("Innovation Center") &&
                    !school.title.includes("Career Elevation") &&
                    !school.title.includes("Guide") && (
                      <li
                        key={school.title}
                        className="grid auto-rows-min grid-cols-1 items-center gap-8 px-3 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-1 xl:px-12"
                      >
                        {school.mainImage && (
                          <div className="relative h-48 overflow-hidden rounded-2xl shadow-lg sm:h-60 lg:h-40">
                            <div className="absolute inset-0 flex items-center justify-center">
                              <img
                                src={urlFor(school.mainImage).width(640).url()}
                                alt=""
                                unoptimized
                                className="h-full w-full object-cover"
                              />
                            </div>
                          </div>
                        )}
                        <div>
                          <h3 className="text-base font-medium tracking-tight text-slate-900">
                            {school.title}
                          </h3>
                          <p className="mt-2 text-sm text-slate-600">
                            <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base">
                              {school.seoDescription && (
                                <>
                                  <p>{school.seoDescription}</p>
                                  <Link to={`/schools/${school.slug.current}`}>
                                    Learn more
                                  </Link>
                                </>
                              )}
                            </div>
                          </p>
                        </div>
                      </li>
                    )}
                </>
              ))}
          </ol>
        </Container>
      </section>
      <Footer />
    </>
  );
}
