import { Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { PortableText } from "@portabletext/react";

export const Accordion = ({ title, children, defaultOpen }) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        /* Use the `open` state to conditionally change the direction of an icon. */
        <div className="pt-2">
          <Disclosure.Button
            className={`flex justify-between w-[calc(100%+2em)] items-center rounded hover:bg-gray-50 -mx-4 px-4 py-2 transition text-left ${
              open && "bg-gray-50"
            }`}
          >
            {title && title}
            <ChevronRightIcon
              className={`w-6 h-6 shrink-0 ${
                open ? "rotate-90 transform" : ""
              }`}
            />
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="my-4 prose max-w-none">
              <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base">
                {children}
              </div>
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
};
