import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { GridPattern } from "../components/GridPattern";

export function Footer() {
  return (
    <footer className="relative pt-5 pb-20 sm:pb-32 sm:pt-14">
      <div className="absolute inset-x-0 top-0 h-32 text-slate-900/10 [mask-image:linear-gradient(white,transparent)]">
        <GridPattern x="50%" />
      </div>
      <div className="relative text-center text-sm text-slate-600">
        <button
          className="inline-flex mb-16 items-center text-base font-medium tracking-tight text-[color:var(--bg-color)] hover:bg-[color:var(--bg-color-light)] rounded-lg bg-[color:var(--bg-color-light)] transition-all hover:text-[color:var(--bg-color)] px-4 py-2 mt-2 hover:opacity-80"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          Return to Top
          <ChevronUpIcon className="w-5 h-5 ml-2" />
        </button>
        <p>
          Copyright &copy; {new Date().getFullYear()} St. Vrain Valley Schools
        </p>
        <p>All rights reserved.</p>
        <br />
        <a
          className="text-[color:var(--bg-color)] transition-all hover:opacity-80 mt-8"
          href="https://issuu.com/svvsd/docs/web-2023-middle_school_options_publication_redesig"
          target="_blank"
        >
          Download a PDF Guide to Middle School Options
        </a>
        <br />
      </div>
    </footer>
  );
}
