import sanityClient from "@sanity/client";

export default sanityClient({
  projectId: "md6vyvkt", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // this is from those question during 'sanity init'
  useCdn: true,
});

export const previewClient = sanityClient({
  projectId: "md6vyvkt",
  dataset: "production",
  apiVersion: "2021-08-29",
  useCdn: false,
  withCredentials: true,
});
