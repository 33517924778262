import { useRef, useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HomeHero() {
  // fade videoBackground in when the iframe is loaded
  const iframeRef = useRef(null);
  useEffect(() => {
    const iframe = iframeRef.current;
    const iframeParent = iframe.parentNode;
    iframe.onload = () => {
      // add opacity-100 after 1000ms
      setTimeout(() => {
        console.log("class added");
        iframeParent.classList.remove("opacity-0");
      }, 1000);
    };
  }, []);

  return (
    <div className="bg-white">
      <main>
        <div>
          {/* Hero card */}
          <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
                <div
                  className="absolute inset-0 bg-[#862633] overflow-hidden"
                  style={{ backdropFilter: "opacity(1)" }}
                >
                  <img
                    src="https://cdn.sanity.io/images/md6vyvkt/production/860ff1048396181069b396bc8e15615818d7150c-1054x666.png"
                    className="w-full h-full object-cover absolute inset-0"
                  />
                  <div className="hidden sm:flex h-full sm:h-auto sm:w-full aspect-h-9 aspect-w-16 blur-[2px] opacity-0 transition-opacity duration-[2000ms] scale-150 lg:scale-[101%] bg-black">
                    <iframe
                      src="https://player.vimeo.com/video/871613199?h=e83decbbb6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&loop=1&autopause=0&controls=0&background=1"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen
                      className="w-full h-full opacity-60"
                      title="MHS - Arts, Athletics,
                      Extracurriculars"
                      ref={iframeRef}
                    ></iframe>
                  </div>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 z-20">
                  <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                    <span className="block text-white">Guide to</span>
                    <span className="block text-[#ffce51]">
                      Middle School Options
                    </span>
                  </h1>
                  <p className="mx-auto mt-6 sm:mt-12 max-w-lg text-center text-base sm:text-xl sm:max-w-3xl text-white font-bold uppercase opacity-90">
                    <span className="py-2 sm:px-4 rounded-full sm:border border-white text-center">
                      Step into a world of possibility
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Logo cloud */}
          <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl py-16 pt-8 px-4 sm:px-6 lg:px-8">
              <div className="relative">
                <blockquote className="mt-10">
                  <div className="mx-auto max-w-3xl text-center text-lg font-medium leading-9 text-gray-900">
                    <p>
                      &ldquo;Choosing a school for your student is an important
                      personal step in charting their future path. Toward this
                      end, St. Vrain offers a diverse and extensive selection of
                      rigorous academic and co-curricular programs that provide
                      you and your student choice in selecting a school that
                      best aligns with their interests and educational
                      goals.&rdquo;
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="md:flex md:items-center md:justify-center">
                      <div className="md:flex-shrink-0">
                        <img
                          className="mx-auto h-10 w-10 rounded-full object-cover object-left"
                          src="https://cdn.sanity.io/images/mx4mn3pt/production/db83c5297f60089c8d439c6c23ec952de2ba6038-300x268.jpg?w=72&h=64&fit=crop&auto=format"
                          alt=""
                        />
                      </div>
                      <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                        <div className="text-base font-medium text-gray-900">
                          Don Hadded Ed.D.
                        </div>

                        <svg
                          className="mx-1 hidden h-5 w-5 text-[#862633] md:block"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M11 0h3L9 20H6l5-20z" />
                        </svg>

                        <div className="text-base font-medium text-gray-500">
                          Superintendent
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  );
}
